export default {
    button: 'iButton',
    icon: 'Icon',
    slider: 'Slider',
    rate: 'Rate',
    upload: 'fcUpload',
    cascader: 'Cascader',
    colorPicker: 'ColorPicker',
    timePicker: 'TimePicker',
    datePicker: 'DatePicker',
    'switch': 'iSwitch',
    select: 'fcSelect',
    checkbox: 'fcCheckbox',
    radio: 'fcRadio',
    inputNumber: 'InputNumber',
    input: 'iInput',
    formItem: 'FormItem',
    form: 'iForm',
    frame: 'fcFrame',
    col: 'iCol',
    row: 'row',
    tree: 'fcTree',
    autoComplete: 'AutoComplete',
    group: 'fcGroup',
    subForm: 'fcSubForm',
    object: 'fcSubForm',
}
